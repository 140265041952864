import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Traffic.style.scss";
import { inject } from "mobx-react";
import { Container } from "../../ui";
import type { Store } from "@inferno/renderer-shared-core";
import type { BlockFragment } from "@ihr-radioedit/inferno-webapi";

interface TrafficProps {
  block?: BlockFragment;
  store?: Store;
}

const TRAFFIC_URI = "https://www.sigalert.com/mobile/?partner=";
const TRAFFIC_BANDS = ["-FM", "-AM", "-FL", "-IP", "-PR", "-DV", "-DEFAULT"];

export const Traffic = inject("store")(({ block, store }: TrafficProps) => {
  if (!store) {
    return null;
  }

  const { site } = store;
  let trafficMarket = site.sections.partners?.traffic_market || "";
  const lat = site.sections.general?.geo_latitude || null;
  const lon = site.sections.general?.geo_longitude || null;

  if (!TRAFFIC_BANDS.some(band => trafficMarket.includes(band))) {
    trafficMarket = site.index.slug.toUpperCase();
  }

  useEffect(() => {
    if (store && block) {
      store.storeBlock(block);
    }
  });
  const { t } = useTranslation();

  return (
    <Container className="traffic-container">
      <div className="component-traffic-map embed-responsive embed-responsive-4by3">
        <iframe
          title={t("traffic_title")}
          src={`${TRAFFIC_URI}${trafficMarket}&lat=${lat}&lon=${lon}&z=1`}
          allowFullScreen={true}
          scrolling="no"
          className="embed-responsive-item"
        />
      </div>
    </Container>
  );
});

export default Traffic;
